<template>
  <div class="user-container">
    <div class="div-photo">
      <label for="file">
        <div class="photo">
          <img id="photo-usr" :src="src" v-if="src !== false && src !== null && error === false" @error="error = true" class="cover" />
          <i class="fas fa-user icon" aria-hidden="true" v-else></i>
        </div>
      </label>
    </div>

    <div id="DivLine" class="div-separation-line"></div>

    <section class="section">
      <div class="div-splitted-inputs">
        <div class="div-input-left">
          <label>N° INTERNO</label>
          <input type="text" :placeholder="user.user_internal" :disabled="true"/>
        </div>

        <div class="div-input-right">
          <label>NOME PROFISSIONAL</label>
          <input type="text" :placeholder="user.professional_name" :disabled="true"/>
        </div>
      </div>

      <div class="div-row">
        <label>NOME COMPLETO</label>
        <input type="text" :placeholder="user.complete_name" :disabled="true" />
      </div>

      <div class="div-row">
        <label for="">FUNÇÃO</label>
        <input type="text" :placeholder="user.user_job" :disabled="true" />
      </div>

      <div class="div-row">
        <label>EMAIL</label>
        <input type="text" :placeholder="user.user_email" :disabled="true" />
      </div>
    </section>
  </div>
</template>

<script>
import { KPI } from "../kpi";

export default {
  name: "profile",
  props: {
    userId: [Number, String],
  },
  data() {
    return {
      user: [],
      user_id: sessionStorage.getItem('logged_user_id'),
      src: null,
      error: false,
    };
  },
  methods: {

    async GetUser() {
      const response = await KPI.CallBackEnd("get", "get-user", {
        params: { id: this.user_id, cycle: localStorage.getItem("cycle") },
      })

      this.user = response.data[0][0]
      this.src = this.user["src"]
    },
  },
  async created() {
    if (history.state.id)
      this.user_id = history.state.id
    
    await this.GetUser()
  },
};
</script>

<style scoped>

.user-container {
  text-align: left;
  display: flex;
  margin: 2% 7% 0 7%;
}

.section {
  width: 100%;
  max-width: 600px;
}

.div-separation-line {
  display: inline-block;
  border-right: solid 2px #6f1afc;
  margin: 0 8% 0 8%;
}

.div-photo { 
  align-content: space-evenly;
}

.photo img {
  align-self: center;
  width: 140px;
  height: 140px;
  border-radius: 50%;
}

.photo {
  align-self: center;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  border: 5px solid white;
  box-shadow: 0 0 15px 5px rgba(30, 40, 50, .2);
  align-content: center;
  text-align-last: center;
}

.icon {
  font-size: 7em;
}

.user-container div input {
  height: 30px;
  border-color: var(--default-gray-color);
}

.cover {
  object-fit: cover;
  width: 150px;
  height: 100px;
}

.div-splitted-inputs {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
}

.div-splitted-inputs input, .div-splitted-inputs select {
  width: -webkit-fill-available !important;
}

.div-input-left {
  max-width: 130px;
}

.div-input-right {
  width: 100%;
}

.div-row {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}
</style>
